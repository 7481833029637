import { Box, Typography, useTheme } from "@mui/material";
import { SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { IconName, VrsIcon, VrsTooltip, verisColors } from "@veris-health/web-core";
import React from "react";
import {
  StyledPreviewSensorLabel,
  StyledSensorDetails,
  StyledSensorLabel,
} from "../../../shared/styledComponents";

export interface SensorLabelProps {
  title?: string;
  iconName?: IconName;
  isFirst?: boolean;
  isLast?: boolean;
  isPreview?: boolean;
  id?: SensorEnum;
  unit?: string;
}

export const SensorLabel = ({
  title,
  iconName,
  isFirst,
  isLast,
  isPreview,
  id,
  outlayingValues,
  unit,
}: SensorLabelProps & { outlayingValues?: boolean }): JSX.Element => {
  const theme = useTheme();
  return isPreview ? (
    <StyledPreviewSensorLabel key={id}>
      <Typography ml={0.5} mr={0.5} variant="body1">
        {title}
      </Typography>
      {unit && <Typography color={() => theme.veris.colors.neutrals["grey-3"]}>{unit}</Typography>}
      {outlayingValues && (
        <VrsTooltip
          title="Chart is rescaled to show outlaying values"
          bcgcolor={`${() => theme.veris.colors.neutrals.black}`}
        >
          <Typography
            variant="h1"
            color={() => theme.veris.colors.errors.normal}
            sx={{
              lineHeight: "1rem",
              paddingTop: () => theme.spacing(0.6),
              cursor: "default",
            }}
          >
            •
          </Typography>
        </VrsTooltip>
      )}
    </StyledPreviewSensorLabel>
  ) : (
    <Box key={id}>
      <StyledSensorLabel isFirst={!!isFirst} isLast={!!isLast}>
        <Box display="flex" flexDirection="row" alignItems="flex-start">
          <Typography color={verisColors.neutrals["grey-5"]} pt={0.5}>
            {title}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />
          {outlayingValues && (
            <VrsTooltip
              title="Chart is rescaled to show outlaying values"
              bcgcolor={`${() => theme.veris.colors.neutrals.black}`}
            >
              <Typography
                variant="h1"
                color={() => theme.veris.colors.errors.normal}
                sx={{
                  cursor: "default",
                }}
              >
                •
              </Typography>
            </VrsTooltip>
          )}
        </Box>

        <StyledSensorDetails>
          {iconName && (
            <VrsIcon
              name={iconName}
              sx={{
                marginTop: () => theme.spacing(0.5),
              }}
              stroke={id === SensorEnum.Weight ? "black" : "auto"}
            />
          )}
        </StyledSensorDetails>
        <StyledSensorDetails>
          {" "}
          {unit && (
            <Typography color={() => theme.veris.colors.neutrals["grey-3"]}>{unit}</Typography>
          )}
        </StyledSensorDetails>
      </StyledSensorLabel>
    </Box>
  );
};
