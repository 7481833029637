import * as React from "react";
import { Box, Typography } from "@mui/material";
import { CareTeamNote } from "@veris-health/med-data-ms/lib/v1";
import { ContentState, convertFromRaw, convertToRaw, EditorState, SelectionState } from "draft-js";
import { useEffect, useRef, useState } from "react";
import DraftJsEditor from "@draft-js-plugins/editor";
import {
  VrsIconButton,
  IconName,
  VrsButton,
  VrsAvatar,
  VrsDivider,
  verisColors,
  dateFormats,
} from "@veris-health/web-core";
import { NotificationDetailItem } from "../../../Notifications/components/VrsNotificationDetails/SymptomNotificationDetails";
import { TitleInput } from "../../containers/PatientCareTeamNotesContainer";
import { Toolbar } from "../../../../ui/components/Editor/Toolbar";
import { Editor } from "../../../../ui/components/Editor";
import { linkDecorator } from "../../../../ui/components/Editor/Link/Link";
import { updateCareTeamNoteAsync } from "../../patientDetailsSlice";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";
import { useProfile } from "../../../../context/profile";
import { utcToLocal } from "../../../../utils/date";
import { textRegxpPattern } from "../../../../constants";

export interface PatientNoteItemProps {
  noteInfo: CareTeamNote;
  open?: boolean;
  onClick?: () => void;
  maxLength?: number;
}

export function NotePreview({
  noteInfo: {
    time_created: timeCreated,
    title: noteTitle,
    full_name: fullName,
    profession,
    picture,
    note,
    time_updated: timeUpdated,
    id,
    med_staff_id: medStaffId,
  },
  maxLength,
}: PatientNoteItemProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [editMode, setEditMode] = useState(false);
  const [editorState, setEditorState] = useState<EditorState | null>(null);
  const [readOnly, setReadOnly] = useState(true);
  const [title, setTitle] = useState(noteTitle || "Untitled");
  const [titleError, setTitleError] = useState("");
  const editorRef = useRef<DraftJsEditor>(null);
  const titleRef = useRef<HTMLInputElement | null>(null);
  const profile = useProfile();

  useEffect(() => {
    setTitle(noteTitle || "Untitled");
    try {
      const parsed = JSON.parse(note);
      setEditorState(EditorState.createWithContent(convertFromRaw(parsed), linkDecorator));
    } catch (e) {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(note), linkDecorator),
      );
    }

    setEditMode(false);
    setReadOnly(true);
  }, [note, noteTitle]);

  const noteDescription = editorState?.getCurrentContent().getPlainText("") || "";

  const onSave = () => {
    if (profile && editorState) {
      const row = convertToRaw(editorState.getCurrentContent());
      dispatch(
        updateCareTeamNoteAsync({
          id: profile.id,
          noteId: id,
          note: { title, note: JSON.stringify(row) },
        }),
      );
      setEditMode(false);
      setTitleError("");
      setReadOnly(true);
      setEditorState(EditorState.createWithContent(convertFromRaw(row), linkDecorator));
    }
  };
  return (
    <Box
      width="calc(100% - 18rem);"
      sx={{
        borderLeft: (theme) => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
        padding: (theme) => theme.spacing(1.2, 3),
        marginTop: "4px",
        overflowY: "auto",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              paddingLeft: (theme) => theme.spacing(0.75),
            }}
          >
            <TitleInput
              autoFocus
              placeholder="Write your title"
              value={title}
              ref={(input: HTMLInputElement | null) => {
                titleRef.current = input;
              }}
              disabled={!editMode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length < 61) {
                  if (textRegxpPattern.test(e.target.value)) {
                    setTitle(e.target.value);
                    setTitleError("");
                  } else setTitleError("You can not enter special characters in the title");
                }
              }}
              onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.code === "Enter" || e.code === "ArrowDown") {
                  e.preventDefault();
                  e.stopPropagation();

                  const initialEditorState = editorRef.current?.getPluginMethods().getEditorState();

                  const currentContent = initialEditorState?.getCurrentContent();
                  if (initialEditorState && currentContent) {
                    editorRef.current
                      ?.getPluginMethods()
                      .setEditorState(
                        EditorState.forceSelection(
                          initialEditorState,
                          SelectionState.createEmpty(currentContent.getBlocksAsArray()[0].getKey()),
                        ),
                      );
                    editorRef.current?.focus();
                  }
                }
              }}
            />
            <Typography
              variant="body"
              sx={{
                visibility: editMode ? "visible" : "hidden",
                fontWeight: "400",
                color: (theme) => theme.veris.colors.neutrals["grey-3"],
              }}
            >
              {title.length}/60
            </Typography>
            {titleError && (
              <Typography variant="caption" color={(theme) => theme.veris.colors.errors.normal}>
                {titleError}
              </Typography>
            )}
            <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gap={2} my={1}>
              <NotificationDetailItem
                label="Date Created"
                value={utcToLocal(timeCreated).format(
                  `${dateFormats["MMMM DD, YYYY"]} • ${dateFormats["h:mm"]} A`,
                )}
              />

              <NotificationDetailItem
                label="Last Modified"
                value={utcToLocal(timeUpdated || timeCreated).format(
                  `${dateFormats["MMMM DD, YYYY"]} • ${dateFormats["h:mm"]} A`,
                )}
              />
              <Box gridColumn="span 2" style={{ alignSelf: "center" }}>
                <Typography variant="subtitle2" color={verisColors.neutrals["grey-mid"]}>
                  Added by
                </Typography>
              </Box>
              {fullName && (
                <Box gridColumn="span 6" display="flex" style={{ alignItems: "center" }}>
                  <VrsAvatar imageUrl={picture} size={25} name={fullName} />
                  <Typography
                    variant="subtitle2"
                    style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                  >
                    {fullName}
                    {profession && ` • ${profession.replaceAll("_", " ")}`}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {medStaffId === profile?.id && (
            <Box minWidth="max-content">
              {editMode ? (
                <VrsIconButton
                  onClick={() => {
                    setTitle(noteTitle || "Untitled");
                    try {
                      const parsed = JSON.parse(note);
                      setEditorState(
                        EditorState.createWithContent(convertFromRaw(parsed), linkDecorator),
                      );
                    } catch (e) {
                      setEditorState(
                        EditorState.createWithContent(
                          ContentState.createFromText(note),
                          linkDecorator,
                        ),
                      );
                    }
                    setEditMode(false);
                    setTitleError("");
                    setReadOnly(true);
                  }}
                  iconProps={{ name: IconName.CloseIcon }}
                />
              ) : (
                <VrsButton
                  buttonType="secondary"
                  onClick={() => {
                    setEditMode(true);
                    setReadOnly(false);
                    titleRef.current?.focus();
                  }}
                >
                  Edit Note
                </VrsButton>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <VrsDivider height="4px" orientation="horizontal" />
      {editorState && (
        <Box
          sx={{
            border: (theme) =>
              editMode ? `1px solid ${theme.veris.colors.neutrals["grey-2"]}` : "none",
            borderRadius: (theme) => +theme.shape.borderRadius * 0.5,
            padding: (theme) => theme.spacing(0.75),
            overflow: "hidden",
            marginTop: "8px",
          }}
        >
          {editMode && (
            <Toolbar
              editorState={editorState}
              setEditorState={setEditorState}
              setReadOnly={setReadOnly}
            />
          )}

          <Editor
            maxLength={maxLength}
            readOnly={readOnly || !editMode}
            editorRef={editorRef}
            editorState={editorState}
            onChange={(e: EditorState) => setEditorState(e)}
          />
        </Box>
      )}
      {editMode && (
        <Box
          sx={{
            justifyContent: "center",
            marginTop: "0.5rem",
            display: "flex",
          }}
        >
          <VrsButton
            type="submit"
            buttonType="primary"
            disabled={noteDescription.trim().length === 0 || title.trim().length === 0}
            onClick={onSave}
          >
            Save changes
          </VrsButton>
        </Box>
      )}
    </Box>
  );
}
