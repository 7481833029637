import { Box, Button, Dialog, Table, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import * as yup from "yup";
import { FieldArray, Formik, getIn, useFormik } from "formik";
import { BaselineSensorDataModel, SensorEnum } from "@veris-health/med-data-ms/lib/v1";
import { IconName, VrsIconButton, VrsIcon, VrsButton, VrsInput } from "@veris-health/web-core";
import axios, { AxiosError } from "axios";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../ui/components/Tables/shared/StyledTableElements";
import { useAppSelector } from "../../../hooks/useAppSelector";
import {
  fetchUnifiedUserSensorsDataAsync,
  selectBarSensorsData,
  selectImplantSensors,
  selectMioSensors,
} from "../measurementSlice";
import { VrsPatientInfo } from "../../shared/interfaces";
import { floatNumberRegexPattern } from "../../../constants";
import { setUserSensorBaseline } from "../api/patientDetailsMeasurementsApi";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import SnackbarUtils from "../../../utils/SnackbarUtils";
import { extractErrorMessage } from "../../shared/helpers";
import { SensorsByDataSource } from "./shared/constants";
import { getSensorsPerSource } from "../../../utils/helpers";

export interface BaselineDialogInterface {
  open: boolean;
  setPatientBaselineDialog: (shouldOpen: boolean) => void;
  currentPatient: VrsPatientInfo;
}

const enum TableColumnNames {
  Empty = "",
  Min = "Minimum",
  Max = "Maximum",
}

interface Column {
  id: TableColumnNames;
  label: TableColumnNames;
  minWidth?: number;
}

const columns: Column[] = [
  { id: TableColumnNames.Empty, label: TableColumnNames.Empty, minWidth: 130 },
  { id: TableColumnNames.Min, label: TableColumnNames.Min },
  { id: TableColumnNames.Max, label: TableColumnNames.Max },
];

interface SensorProps {
  [id: string]: {
    name: string;
    type: SensorEnum;
    measurementUnit?: string;
  };
}

const Sensors: SensorProps = {
  temp: { name: "Temperature", type: SensorEnum.Temp, measurementUnit: "ºF" },
  sys: { name: "Systolic BP", type: SensorEnum.BloodPressure },
  dia: { name: "Diastolic BP", type: SensorEnum.BloodPressure },
  oximeter: { name: "Oxygen Saturation", type: SensorEnum.Oximeter },
  bpm: { name: "Heart Rate", type: SensorEnum.Bpm, measurementUnit: "bpm" },
  motion: { name: "Motion", type: SensorEnum.Motion, measurementUnit: "steps" },
  rpm: { name: "Respiratory rate", type: SensorEnum.Rpm },
};

const icons = {
  [Sensors.temp.name]: IconName.Temperature,
  [Sensors.sys.name]: IconName.BloodPressure,
  [Sensors.dia.name]: IconName.BloodPressure,
  [Sensors.oximeter.name]: IconName.Oximeter,
  [Sensors.bpm.name]: IconName.BPM,
  [Sensors.motion.name]: IconName.Motion,
  [Sensors.rpm.name]: IconName.RPM,
};

type BaselineSensorValue = {
  data: {
    name: string;
    type: SensorEnum;
    measurementUnit?: string;
  };
  min: number | undefined;
  max: number | undefined;
  disabledMin?: boolean;
  disabledMax?: boolean;
};

const validationSchema = yup.object().shape({
  sensors: yup.array().of(
    yup.object().shape({
      min: yup
        .number()
        .test("min-valid", "Min must be lower or equal to max.", (value, context) => {
          if (value && context.parent.max) {
            if (value <= context.parent.max) return true;
            return false;
          }
          return true;
        })
        .positive()
        .required("Min value is required"),

      max: yup
        .number()
        .test("max-valid", "Max must be greater or equal to min.", (value, context) => {
          if (value && context.parent.min && !context.parent.disabledMax) {
            if (value >= context.parent.min) return true;
            return false;
          }
          return true;
        })
        .test("max-empty", "Max value is required.", (value, context) => {
          if (!context.parent.disabledMax && !value) return false;
          return true;
        })
        .nullable()
        .positive(),
    }),
  ),
});

interface ApiErrorResponse {
  detail?: string | string[] | { msg: string }[];
}

export default function BaselineDialog({
  open,
  setPatientBaselineDialog,
  currentPatient,
}: BaselineDialogInterface): JSX.Element {
  const mioSensorsData = useAppSelector((state) => selectMioSensors(state, +currentPatient.id));
  const implantSensorsData = useAppSelector((state) =>
    selectImplantSensors(state, +currentPatient.id),
  );
  const patientSources = currentPatient?.dataSources;
  const combinedSensorsList = getSensorsPerSource(patientSources || [], SensorsByDataSource, "id");
  const externalData = useAppSelector((state) => selectBarSensorsData(state, currentPatient.id));

  const unifiedSensorsInitalsData: Record<string, BaselineSensorValue> = {
    temp: {
      data: Sensors.temp,
      min: implantSensorsData?.temp?.normalRange.min || mioSensorsData?.temp?.normalRange.min,
      max: implantSensorsData?.temp?.normalRange.max || mioSensorsData?.temp?.normalRange.max,
    },
    rpm: {
      data: Sensors.rpm,
      min: implantSensorsData?.rpm?.normalRange.min,
      max: implantSensorsData?.rpm?.normalRange.max,
    },
    bpm: {
      data: Sensors.bpm,
      min: implantSensorsData?.bpm?.normalRange.min || mioSensorsData?.bpm?.normalRange.min,
      max: implantSensorsData?.bpm?.normalRange.max || mioSensorsData?.bpm?.normalRange.max,
    },
    motion: {
      data: Sensors.motion,
      min:
        implantSensorsData?.motion?.normalRange.min ||
        mioSensorsData?.motion?.normalRange.min ||
        externalData?.motion?.normalRange.min,
      max:
        implantSensorsData?.motion?.normalRange.max ||
        mioSensorsData?.motion?.normalRange.max ||
        externalData?.motion?.normalRange.max,
      disabledMax: true,
    },
    sys: {
      data: Sensors.sys,
      min: mioSensorsData?.blood_pressure?.normalRange.min.sys,
      max: mioSensorsData?.blood_pressure?.normalRange.max.sys,
    },
    dia: {
      data: Sensors.dia,
      min: mioSensorsData?.blood_pressure?.normalRange.min.dia,
      max: mioSensorsData?.blood_pressure?.normalRange.max.dia,
    },
    oximeter: {
      data: Sensors.oximeter,
      min: mioSensorsData?.oximeter?.normalRange.min,
      max: mioSensorsData?.oximeter?.normalRange.max,
      disabledMax: true,
    },
  };

  const matchedSensors: BaselineSensorValue[] = Object.values(combinedSensorsList)
    .filter((el) => el.id !== "weight" && el.id !== "ecg")
    .flatMap((value) => {
      if (value.id === "blood_pressure")
        return [{ ...unifiedSensorsInitalsData.sys }, { ...unifiedSensorsInitalsData.dia }];
      return { ...unifiedSensorsInitalsData[value.id] };
    });

  const dispatch = useAppDispatch();

  const fetchUserSensorData = ({ id }: { id: number }) => {
    dispatch(fetchUnifiedUserSensorsDataAsync({ id }));
  };

  const initialValues: { sensors: BaselineSensorValue[] } = {
    sensors: matchedSensors,
  };

  const showErrorMessages = (error: AxiosError<ApiErrorResponse>) => {
    if (error.response) {
      const errorMsgs = error?.response?.data?.detail;
      if (Array.isArray(errorMsgs)) {
        errorMsgs.map((e) => {
          const msg = typeof e === "string" ? e : e.msg;
          return SnackbarUtils.error(msg);
        });
      } else {
        const msg = extractErrorMessage(error);
        SnackbarUtils.error(msg);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const sys = values.sensors.find((sensor) => sensor.data.name === "Systolic BP");
      const dia = values.sensors.find((sensor) => sensor.data.name === "Diastolic BP");
      const bpFormatted = {
        sensor_type: sys?.data.type,
        min: { sys: sys?.min, dia: dia?.min },
        max: { sys: sys?.max, dia: dia?.max },
      };
      const newValues = values.sensors
        .filter((value) => value.data.type !== "blood_pressure")
        .map((sensor) => {
          return {
            sensor_type: sensor.data.type,
            min: sensor.min,
            max: sensor.max,
          } as BaselineSensorDataModel;
        });

      if (sys && dia) {
        newValues.push(bpFormatted as BaselineSensorDataModel);
      }

      try {
        await setUserSensorBaseline(+currentPatient.id, newValues);
        fetchUserSensorData({ id: currentPatient.id });
        setPatientBaselineDialog(false);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          showErrorMessages(error);
        }
      }
    },
  });
  const { errors, handleSubmit, values } = formik;

  const handleResetToDefault = async () => {
    const bpFormatted = {
      sensor_type: "blood_pressure",
      min: { sys: null, dia: null },
      max: { sys: null, dia: null },
    };
    const newValues = values.sensors
      .filter((value) => value.data.type !== "blood_pressure")
      .map((sensor) => {
        return {
          sensor_type: sensor.data.type,
          min: null,
          max: null,
        } as unknown as BaselineSensorDataModel;
      });
    await setUserSensorBaseline(+currentPatient.id, [
      ...newValues,
      bpFormatted as unknown as BaselineSensorDataModel,
    ]).then(() => fetchUserSensorData({ id: currentPatient.id }));

    setPatientBaselineDialog(false);
  };
  return (
    <Dialog open={open}>
      <Box sx={{ minWidth: "600px", minHeight: "400px" }} p={1}>
        <Box display="flex" p={2} justifyContent="space-between" pt={4}>
          <Typography variant="h3"> Set Patient’s Normal Range Values </Typography>
          <VrsIconButton
            onClick={() => {
              setPatientBaselineDialog(false);
            }}
            iconProps={{ name: IconName.CloseIcon }}
          />
        </Box>
        <Box p={2} pb={4} overflow="auto">
          <Table>
            <TableHead
              sx={{
                borderBottom: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-light"]}`,
                marginBottom: (theme) => theme.spacing(1),
                backgroundColor: (theme) => theme.veris.colors.neutrals["grey-1"],
              }}
            >
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell key={column.id} sx={{ minWidth: column.minWidth }}>
                    <Typography
                      variant="bodyMedium"
                      component="span"
                      sx={{
                        textTransform: "uppercase",
                        color: (theme) => theme.veris.colors.neutrals["grey-4"],
                      }}
                    >
                      {column.label}
                    </Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <StyledTableBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={() => handleSubmit()}
              >
                <FieldArray
                  name="sensors"
                  render={() => {
                    return values.sensors.map((sensor: BaselineSensorValue, index) => {
                      const min = `sensors[${index}].min`;
                      const errorMin = getIn(errors, min);

                      const max = `sensors[${index}].max`;
                      const errorMax = getIn(errors, max);
                      return (
                        <React.Fragment key={sensor.data.name}>
                          <StyledTableRow>
                            {columns.map((column) => {
                              return (
                                <>
                                  <StyledTableCell key={column.id}>
                                    {column.id === TableColumnNames.Empty && (
                                      <Box display="flex" alignItems="center">
                                        <VrsIcon name={icons[sensor.data.name]} size={24} />
                                        <Typography
                                          variant="subtitle1"
                                          color={(theme) => theme.veris.colors.neutrals["grey-4"]}
                                          ml={1}
                                        >
                                          {sensor.data.name}
                                        </Typography>
                                      </Box>
                                    )}
                                    <Box display="flex" alignItems="baseline" gap={1.5}>
                                      {column.id === TableColumnNames.Min && (
                                        <>
                                          <VrsInput
                                            name={min}
                                            value={sensor.min || ""}
                                            error={Boolean(errorMin)}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                floatNumberRegexPattern.test(e.target.value)
                                              )
                                                formik.handleChange(e);
                                            }}
                                            maxInputLength={6}
                                            typography="body1"
                                            width="65px"
                                            disabled={sensor.disabledMin}
                                            padding={{ left: "15px", top: "22px" }}
                                          />
                                          {sensor.data?.measurementUnit && (
                                            <Typography
                                              variant="body"
                                              color={(theme) =>
                                                theme.veris.colors.neutrals["grey-4"]
                                              }
                                            >
                                              {sensor.data.measurementUnit}
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    </Box>
                                    <Box display="flex" alignItems="baseline" gap={1.5}>
                                      {column.id === TableColumnNames.Max && (
                                        <>
                                          <VrsInput
                                            name={max}
                                            value={sensor.max || ""}
                                            error={Boolean(errorMax)}
                                            onChange={(e) => {
                                              if (
                                                e.target.value === "" ||
                                                floatNumberRegexPattern.test(e.target.value)
                                              )
                                                formik.handleChange(e);
                                            }}
                                            maxInputLength={6}
                                            typography="body1"
                                            width="65px"
                                            disabled={sensor.disabledMax}
                                            padding={{ left: "15px", top: "22px" }}
                                          />
                                          {sensor.data?.measurementUnit && (
                                            <Typography
                                              variant="body"
                                              color={(theme) =>
                                                theme.veris.colors.neutrals["grey-4"]
                                              }
                                            >
                                              {sensor.data.measurementUnit}
                                            </Typography>
                                          )}
                                        </>
                                      )}
                                    </Box>
                                  </StyledTableCell>
                                </>
                              );
                            })}
                          </StyledTableRow>

                          <StyledTableRow>
                            <StyledTableCell
                              sx={{
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "0.875rem",
                                lineHeight: "1rem",
                                color: (theme) => theme.veris.colors.errors.normal,
                                textAlign: "end",
                              }}
                              cellPadding={errorMin ? 1 : 0}
                              colSpan={2}
                            >
                              {errorMin && (
                                <Typography
                                  color={(theme) => theme.veris.colors.errors.normal}
                                  variant="caption"
                                >
                                  {errorMin}
                                </Typography>
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                fontStyle: "normal",
                                fontWeight: "normal",
                                fontSize: "0.875rem",
                                lineHeight: "1rem",
                                color: (theme) => theme.veris.colors.errors.normal,
                              }}
                              cellPadding={errorMax ? 1 : 0}
                            >
                              {errorMax && (
                                <Typography
                                  color={(theme) => theme.veris.colors.errors.normal}
                                  variant="caption"
                                >
                                  {errorMax}
                                </Typography>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        </React.Fragment>
                      );
                    });
                  }}
                />
              </Formik>
            </StyledTableBody>
          </Table>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Button onClick={handleResetToDefault}>
          <Typography
            color={(theme) => theme.veris.colors.amethyst.normal}
            sx={{
              textTransform: "none",
              textDecoration: "underline",
              fontWeight: 400,
            }}
          >
            Reset to default
          </Typography>
        </Button>
        <Box display="flex" gap={2}>
          <VrsButton onClick={() => setPatientBaselineDialog(false)} buttonType="secondary">
            Cancel
          </VrsButton>
          <VrsButton
            disabled={!formik.isValid}
            onClick={() => {
              handleSubmit();
            }}
            buttonType="primary"
          >
            Save Changes
          </VrsButton>
        </Box>
      </Box>
    </Dialog>
  );
}
