import { Box, Popover, Typography, useTheme } from "@mui/material";
import { QolScoreItem } from "@veris-health/user-ms/lib/v1";
import dayjs from "dayjs";
import React from "react";
import { ResponsiveContainer, LineChart, Tooltip, XAxis, Line, YAxis } from "recharts";
import { verisColors, verisTypography, dateFormats } from "@veris-health/web-core";
import {
  ChartTooltip,
  TooltipWrapperProps,
} from "../../../features/PatientDetailsMeasurements/components/shared/tooltips";

const CustomTooltipWrapper = ({ payload }: Omit<TooltipWrapperProps, "sensorsGraphView">) => {
  const theme = useTheme();

  if (payload && payload.length) {
    const sensorData = payload[0]?.payload;

    return (
      <ChartTooltip>
        <Box display="flex">
          <Typography
            component="span"
            variant="caption"
            color={verisColors.neutrals["grey-mid"]}
            sx={{ width: "35px", marginRight: () => theme.spacing(1), textAlign: "left" }}
          >
            Index
          </Typography>

          <Typography
            component="span"
            variant="caption"
            sx={{
              marginRight: "auto",
            }}
          >
            {sensorData?.eq5d_index}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography
            component="span"
            variant="caption"
            color={verisColors.neutrals["grey-mid"]}
            sx={{ width: "35px", marginRight: () => theme.spacing(1), textAlign: "left" }}
          >
            Date
          </Typography>

          <Typography
            component="span"
            variant="caption"
            sx={{
              marginRight: "auto",
            }}
          >
            {dayjs(sensorData.timestamp).format(dateFormats["MMM DD YYYY"])}
          </Typography>
        </Box>
      </ChartTooltip>
    );
  }
  return null;
};

export interface QoLScoreItemFormatted extends QolScoreItem {
  dt: number;
}

export interface VrsQofLTrendProps {
  data: QoLScoreItemFormatted[];
  open: boolean;
  toggleDialog: () => void;
  anchorEl: HTMLButtonElement | null;
}

export function VrsQofLTrend({
  data,
  open,
  toggleDialog,
  anchorEl,
}: VrsQofLTrendProps): JSX.Element {
  const theme = useTheme();
  return (
    <Popover
      open={open}
      onClose={toggleDialog}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorEl={anchorEl}
      PaperProps={{
        sx: {
          padding: () => theme.spacing(1, 2),
          overflow: "visible",
          mt: 2.1,
          "&:before": {
            content: '""',
            position: "absolute",
            left: 35,
            width: 20,
            height: 20,
            bgcolor: () => theme.palette.background.paper,
            transform: "translateY(-50%) rotate(45deg)",
          },
        },
      }}
    >
      <Typography variant="body1" color={() => theme.veris.colors.neutrals["grey-3"]} my={2}>
        Quality Of Life Trend ( last two months ).
      </Typography>
      {data.length > 0 ? (
        <ResponsiveContainer width="99%" height={100} minWidth={300}>
          <LineChart data={data}>
            <Tooltip content={<CustomTooltipWrapper />} />
            <Line
              isAnimationActive={false}
              dataKey="eq5d_index"
              type="monotone"
              cursor="pointer"
              strokeWidth={2}
              stroke={verisColors.amethyst.normal}
              fill={verisColors.amethyst.normal}
            />
            <XAxis
              scale="time"
              dataKey="dt"
              tickFormatter={(date: number) => {
                return dayjs.unix(date).format(dateFormats["MM/DD/YYYY"]);
              }}
              type="number"
              tick={{
                fontSize: verisTypography.caption?.fontSize,
                fontFamily: verisTypography.caption?.fontFamily || "",
                fontWeight: verisTypography.caption?.fontWeight,
                color: verisColors.neutrals["grey-3"],
              }}
              stroke={verisColors.neutrals["grey-3"]}
              domain={[data[0].dt, data[data.length - 1].dt]}
              interval="preserveStartEnd"
            />
            <YAxis hide domain={[-11, 100]} />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography variant="caption" color={() => theme.veris.colors.neutrals["grey-4"]}>
            No data available.
          </Typography>
        </Box>
      )}
    </Popover>
  );
}
