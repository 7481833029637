import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
  useTheme,
} from "@mui/material";
import { VrsIcon, IconName, verisColors } from "@veris-health/web-core";
import React from "react";
import { Virtuoso } from "react-virtuoso";

export interface RPMChartProps {
  timePassed: number;
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "33%",
  margin: theme.spacing(0.3),
  height: 20,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.veris.colors.neutrals["grey-2"],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.veris.colors.amethyst.soft,
  },
}));

function calculateMinuteChunksPercentage(minutes: number) {
  // If the given number of minutes is greater than 60, calculate the percentage for each 20-minute chunk, along with any remaining minutes
  const chunks = [-1, -1, -1];
  let remainingMinutes = minutes;
  let chunkIndex = 0;
  while (remainingMinutes > 0 && chunkIndex < chunks.length) {
    const chunkSize = Math.min(20, remainingMinutes);
    const percentage = Math.round((chunkSize / 20) * 100);
    chunks[chunkIndex] += percentage;
    remainingMinutes -= chunkSize;
    chunkIndex += 1;
  }

  return chunks;
}

export function RPMChart({ timePassed }: RPMChartProps): JSX.Element {
  const theme = useTheme();
  const timeInMinutes = timePassed / 60;
  const remainingSeconds = timePassed % 60;
  const [chunk1, chunk2, chunk3] = calculateMinuteChunksPercentage(timeInMinutes);

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={0.5}>
        <VrsIcon name={IconName.Calendar} size={12} stroke={verisColors.neutrals["grey-3"]} />
        <Typography
          color={() => theme.veris.colors.neutrals["grey-3"]}
          variant="captionSemibold"
          pt={0.5}
        >
          {Math.floor(timeInMinutes)}{" "}
          <Typography color={() => theme.veris.colors.neutrals["grey-3"]} variant="caption">
            min {remainingSeconds} sec
          </Typography>
        </Typography>
      </Box>
      <Virtuoso
        style={{ height: 40, overflow: "hidden" }}
        totalCount={1}
        itemContent={() => (
          <Box display="flex">
            <BorderLinearProgress variant="determinate" value={chunk1} />
            <BorderLinearProgress variant="determinate" value={chunk2} />
            <BorderLinearProgress variant="determinate" value={chunk3} />
          </Box>
        )}
      />
    </Box>
  );
}
