import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import {
  AppointmentAttendeeStatusChangeEnum,
  AppointmentStatusEnum,
} from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import {
  AppointmentConfirmationNotificationTextValues,
  AppointmentModificationTextValues,
} from "@veris-health/communication-ms/lib/v1";
import {
  AppointmentCancellationNotificationViewData,
  AppointmentConfirmationNotificationViewData,
  AppointmentModificationNotificationViewData,
  AppointmentReminderNotificationViewData,
  AppointmentRequestNotificationViewData,
  NotificationType,
} from "@veris-health/communication-ms/lib/v1/api";
import { VrsButton, verisColors, dateFormats } from "@veris-health/web-core";
import { PastAppointments } from "./PastAppointments";
import { NotificationFooter } from "./NotificationFooter";
import { replaceRouteParam, Routes } from "../../../../routes-config";
import { NotificationHeader } from "./NotificationHeader";
import { VrsPatientInfo } from "../../../shared/interfaces";
import { NotificationDetailItem } from "./SymptomNotificationDetails";
import { useNotification } from "../../hooks/useNotification";
import { useAppointment } from "../../hooks/useAppointment";
import { utcToLocal } from "../../../../utils/date";
import { isSystemNotification } from "../../../../constants";
import { setEventCreationVisibility } from "../../../Calendar/calendarSlice";
import { useAppDispatch } from "../../../../hooks/useAppDispatch";

interface AppointmentNotificationProps {
  data:
    | AppointmentCancellationNotificationViewData
    | AppointmentConfirmationNotificationViewData
    | AppointmentModificationNotificationViewData
    | AppointmentReminderNotificationViewData
    | AppointmentRequestNotificationViewData;
}

export function AppointmentNotification({
  data,
}: AppointmentNotificationProps): JSX.Element | null {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const activeNotification = useNotification();
  if (!activeNotification) return null;
  const systemNotification = isSystemNotification(
    activeNotification?.payload.data.type as unknown as NotificationType,
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <NotificationHeader
        notificationDetails={activeNotification.payload.data as unknown as VrsPatientInfo}
        date={activeNotification.date_created}
        systemNotification={systemNotification}
      />
      <Box
        paddingX={1.7}
        sx={{ borderBottom: (theme) => `2px solid ${theme.veris.colors.neutrals["grey-light"]}` }}
        my={2}
      >
        <Typography variant="subtitle2" p={0}>
          {"text" in activeNotification.payload.data &&
            "text-values" in activeNotification.payload.data &&
            formatNotificationDetailsText(
              String(activeNotification.payload.data.text),
              activeNotification.payload.data["text-values"],
            )}
        </Typography>
        <Box mb={3} mt={3}>
          {!data.patient ? (
            <Typography mb={2.3} variant="h6" fontWeight={600} pt={2}>
              This Patient was Deleted
            </Typography>
          ) : (
            <>
              <Typography mb={2.3} variant="h6" fontWeight={600}>
                {activeNotification.payload.title}
              </Typography>
              {data.type === "appointment-cancellation" && <AppointmentCancellation {...data} />}
              {data.type === "appointment-confirmation" && <AppointmentConfirmation {...data} />}
              {data.type === "appointment-request" && <AppointmentRequest {...data} />}
              {data.type === "appointment-reminder" && <AppointmentReminder {...data} />}
              {data.type === "appointment-modification" && <AppointmentModification {...data} />}
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          paddingBottom: "1rem",
        }}
      >
        {data.patient && (
          <>
            <PastAppointments
              patientId={Number(data.patient.id)}
              patientName={
                data.patient.preferred_name ? data.patient.preferred_name : data.patient.full_name
              }
            />
            <NotificationFooter
              notificationType={activeNotification.type}
              onSeePatientProfileClick={() =>
                history.push(
                  replaceRouteParam("PATIENT_DETAILS", ":userId", String(data.patient?.id)),
                )
              }
              onScheduleAppointmentClick={() => {
                history.push(Routes.CALENDAR);
                dispatch(
                  setEventCreationVisibility({
                    isOpen: true,
                    patientId: Number(data.patient?.id),
                  }),
                );
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

function AppointmentCancellation({
  utc_start: utcStart,
  utc_end: utcEnd,
  venue,
  patient,
}: AppointmentCancellationNotificationViewData) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  if (!patient || !utcStart || !utcEnd) {
    return null;
  }

  return (
    <>
      <AppointmentDetails
        start={utcStart}
        end={utcEnd}
        name={patient.preferred_name || patient.full_name}
        venue={venue}
      />
      <Box display="flex" gap={1} sx={{ marginTop: "1rem" }} justifyContent="end" width="100%">
        <VrsButton
          buttonType="secondary"
          sx={{ marginLeft: "auto", display: "flex" }}
          onClick={() => {
            history.push(Routes.CALENDAR);
            dispatch(
              setEventCreationVisibility({
                isOpen: true,
                patientId: Number(patient.id),
              }),
            );
          }}
        >
          Schedule another date
        </VrsButton>
      </Box>
    </>
  );
}

function AppointmentConfirmation({
  venue,
  utc_start: utcStart,
  utc_end: utcEnd,
  patient,
  appointment_id: appointmentId,
}: AppointmentConfirmationNotificationViewData) {
  const { appointment, onStatusChange } = useAppointment(appointmentId);

  const disableCancelAppointment =
    !appointment || appointment.status === AppointmentStatusEnum.Cancelled;

  if (!patient || !utcStart || !utcEnd) {
    return null;
  }

  return (
    <>
      <AppointmentDetails
        start={utcStart}
        end={utcEnd}
        name={patient.preferred_name || patient.full_name}
        venue={venue}
      />
      <Box display="flex" gap={1} sx={{ marginTop: "1rem" }} justifyContent="end" width="100%">
        <VrsButton
          buttonType="secondary"
          onClick={() => onStatusChange(AppointmentAttendeeStatusChangeEnum.Declined)}
          disabled={disableCancelAppointment}
        >
          Decline
        </VrsButton>
      </Box>
    </>
  );
}

function AppointmentRequest({
  utc_start: utcStart,
  utc_end: utcEnd,
  venue,
  appointment_id: appointmentId,
  patient,
}: AppointmentRequestNotificationViewData) {
  const history = useHistory();
  const { onStatusChange, appointment } = useAppointment(appointmentId);

  const disableAcceptAppointment =
    !appointment || appointment.status !== AppointmentStatusEnum.Proposed;
  const disableCancelAppointment =
    !appointment || appointment.status === AppointmentStatusEnum.Cancelled;

  const isAppointmentPassed = dayjs(appointment?.endTime).isBefore(dayjs());
  const showProposeNewDate =
    !isAppointmentPassed &&
    !disableCancelAppointment &&
    appointment?.status !== AppointmentStatusEnum.Booked;

  if (!patient || !utcStart || !utcEnd) {
    return null;
  }

  return (
    <>
      <AppointmentDetails
        start={utcStart}
        end={utcEnd}
        venue={venue}
        name={patient.preferred_name || patient.full_name}
      />
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{ marginTop: "1rem" }}
        justifyContent="space-between"
        width="100%"
      >
        <Box>
          {showProposeNewDate && (
            <Typography
              variant="subtitle2"
              color={verisColors.amethyst.normal}
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                history.push({
                  pathname: Routes.CALENDAR,
                  state: {
                    appointment,
                  },
                })
              }
            >
              Propose new time
            </Typography>
          )}
        </Box>

        <Box display="flex" gap={2}>
          <VrsButton
            buttonType="secondary"
            disabled={disableCancelAppointment}
            onClick={() => onStatusChange(AppointmentAttendeeStatusChangeEnum.Declined)}
          >
            Decline
          </VrsButton>
          <VrsButton
            buttonType="secondary"
            disabled={disableAcceptAppointment}
            onClick={() => onStatusChange(AppointmentAttendeeStatusChangeEnum.Accepted)}
          >
            Accept
          </VrsButton>
        </Box>
      </Box>
    </>
  );
}

function AppointmentModification({
  old,
  new: newData,
  appointment_id: appointmentId,
}: AppointmentModificationNotificationViewData) {
  const { appointment, onStatusChange } = useAppointment(appointmentId);

  if (!old || !newData) {
    return null;
  }

  const utcOldStart = utcToLocal(old.utc_start);
  const utcOldEnd = utcToLocal(old.utc_end);
  const oldStartTime = utcOldStart.format(`${dateFormats["hh:mm"]} A`);
  const oldEndTime = utcOldEnd.format(`${dateFormats["hh:mm"]} A`);
  const oldDate = utcOldStart.format(dateFormats["MMMM DD, YYYY"]);

  const utcNewStart = utcToLocal(newData.utc_start);
  const utcNewEnd = utcToLocal(newData.utc_end);
  const newStartTime = utcNewStart.format(`${dateFormats["hh:mm"]} A`);
  const newEndTime = utcNewEnd.format(`${dateFormats["hh:mm"]} A`);
  const newDate = utcNewStart.format(dateFormats["MMMM DD, YYYY"]);

  return (
    <>
      <Box display="grid" gridTemplateColumns="repeat(16, 1fr)" gap={2.3}>
        <Box gridColumn="span 8">
          <Typography variant="subtitle2" fontWeight="bold">
            Old
          </Typography>
        </Box>
        <Box gridColumn="span 8">
          <Typography variant="subtitle2" fontWeight="bold">
            New proposal
          </Typography>
        </Box>
        <NotificationDetailItem label="Date" value={oldDate} />
        <NotificationDetailItem label="Date" value={newDate} />
        <NotificationDetailItem label="Time" value={`${oldStartTime} - ${oldEndTime}`} />
        <NotificationDetailItem label="Time" value={`${newStartTime} - ${newEndTime}`} />
        <NotificationDetailItem label="Venue" value={capitalize(old.venue)} />
        <NotificationDetailItem label="Venue" value={capitalize(old.venue)} />
      </Box>
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{ marginTop: "1rem" }}
        justifyContent="end"
        width="100%"
      >
        <Box display="flex" gap={2}>
          <VrsButton
            buttonType="secondary"
            disabled={
              appointment?.status !== AppointmentStatusEnum.Proposed &&
              appointment?.status !== AppointmentStatusEnum.Booked
            }
            onClick={() => onStatusChange(AppointmentAttendeeStatusChangeEnum.Declined)}
          >
            Decline
          </VrsButton>
          <VrsButton
            buttonType="secondary"
            disabled={appointment?.status !== AppointmentStatusEnum.Proposed}
            onClick={() => onStatusChange(AppointmentAttendeeStatusChangeEnum.Accepted)}
          >
            Accept
          </VrsButton>
        </Box>
      </Box>
    </>
  );
}

function AppointmentReminder({
  venue,
  appointment_id: appointmentId,
  utc_start: utcStart,
  utc_end: utcEnd,
  patient,
}: AppointmentReminderNotificationViewData) {
  const { appointment, onStatusChange } = useAppointment(appointmentId);

  const disableCancelAppointment =
    !appointment || appointment.status === AppointmentStatusEnum.Cancelled;

  if (!patient || !utcStart || !utcEnd) {
    return null;
  }

  return (
    <>
      <AppointmentDetails
        start={utcStart}
        end={utcEnd}
        name={patient.preferred_name || patient.full_name}
        venue={venue}
      />
      <Box display="flex" gap={1} sx={{ marginTop: "1rem" }} justifyContent="end" width="100%">
        <VrsButton
          buttonType="secondary"
          onClick={() => onStatusChange(AppointmentAttendeeStatusChangeEnum.Declined)}
          disabled={disableCancelAppointment}
        >
          Decline
        </VrsButton>
      </Box>
    </>
  );
}

interface AppointmentDetailsProps {
  start: string;
  end: string;
  venue: string;
  name: string;
}

function AppointmentDetails({ start, end, venue, name }: AppointmentDetailsProps) {
  const utcStartTime = utcToLocal(start);
  const startTime = utcStartTime.format(`${dateFormats["hh:mm"]} A`);
  const endTime = utcToLocal(end).format(`${dateFormats["hh:mm"]} A`);
  const date = utcStartTime.format(dateFormats["MMMM DD, YYYY"]);
  return (
    <Box display="grid" gridTemplateColumns="repeat(8, 1fr)" gap={2.3}>
      <NotificationDetailItem label="Date" value={date} />
      <NotificationDetailItem label="Time" value={`${startTime} - ${endTime}`} />
      <NotificationDetailItem label="Venue" value={capitalize(venue)} />
      <NotificationDetailItem label="Patient Name" value={name} />
    </Box>
  );
}

export function formatNotificationDetailsText(
  text: string,
  textValues?: AppointmentConfirmationNotificationTextValues | AppointmentModificationTextValues,
): string {
  if (textValues) {
    return Object.keys(textValues).reduce((acc, curr) => {
      type textValues =
        | AppointmentConfirmationNotificationTextValues
        | AppointmentModificationTextValues;
      const value = textValues[curr as keyof textValues];
      const date = value
        ? `${utcToLocal(value).format(dateFormats["MMMM DD, YYYY"])} at ${utcToLocal(value).format(
            `${dateFormats["hh:mm"]} A`,
          )}`
        : "";
      return acc.replaceAll(`{${curr}}`, date);
    }, text);
  }

  return text;
}
