import React from "react";
import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { ActiveMedication } from "@veris-health/med-data-ms/lib/v1";
import dayjs from "dayjs";
import {
  VrsButton,
  VrsTooltip,
  verisColors,
  VrsErrorMsg,
  dateFormats,
} from "@veris-health/web-core";
import { Status } from "../../../features/shared/interfaces";
import { EMPTY_DATASET_MESSAGE } from "../../../constants";

export interface VrsActiveMedsProps {
  activeMedications: ActiveMedication[] | [];
  activeMedicationStatus?: Status;
  onActiveMedicationFail?: () => void;
  onViewAllClick?: () => void;
}

export function MedicationItem({ medication }: { medication: ActiveMedication }): JSX.Element {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        justifyContent: "space-between",
        margin: () => theme.spacing(1.25, 0),
      }}
      key={medication.name}
      gap={0.5}
    >
      <Box sx={{ display: "flex" }} whiteSpace="nowrap" maxWidth="150px">
        <VrsTooltip bcgcolor={verisColors.neutrals.black} title={medication.name || "N/A"}>
          <Typography
            variant="body1"
            overflow="hidden"
            textOverflow="ellipsis"
            color={() => theme.veris.colors.neutrals["grey-4"]}
            sx={{ fontWeight: "400", cursor: "pointer" }}
          >
            {medication.name || "N/A"}
          </Typography>
        </VrsTooltip>
      </Box>
      <Box sx={{ display: "flex" }} whiteSpace="nowrap">
        <Typography
          variant="body1"
          overflow="hidden"
          textOverflow="ellipsis"
          color={() => theme.veris.colors.neutrals["grey-4"]}
          sx={{ fontWeight: "400" }}
        >
          • {dayjs(medication.prescribedAt).format(dateFormats["MMM DD, YYYY"])}
        </Typography>
      </Box>
    </Box>
  );
}

export function VrsActiveMeds({
  activeMedications,
  activeMedicationStatus,
  onViewAllClick,
  onActiveMedicationFail,
}: VrsActiveMedsProps): JSX.Element {
  const theme = useTheme();
  return (
    <Box p={1.25} maxHeight={250}>
      {activeMedicationStatus === "loading" ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          data-test-hook="activeMeds-loading"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            data-test-hook="activeMeds-container"
          >
            <Typography
              variant="body1"
              component="span"
              color={() => theme.veris.colors.neutrals["grey-4"]}
              sx={{ textTransform: "uppercase" }}
            >
              ACTIVE MEDICATION
            </Typography>
            {activeMedicationStatus !== "failed" && (
              <VrsButton
                buttonType="quaternary"
                onClick={onViewAllClick}
                sx={{ padding: () => `${theme.spacing(0.5)} ${theme.spacing(1)}` }}
              >
                <Typography variant="caption" pt={0.25}>
                  View all
                </Typography>
              </VrsButton>
            )}
          </Box>
          <Box overflow="scroll" maxHeight={150} data-test-hook="activeMeds-medications">
            {activeMedicationStatus !== "failed" ? (
              <>
                {activeMedications.length === 0 && (
                  <Typography
                    color={() => theme.veris.colors.neutrals["grey-3"]}
                    variant="body"
                    pt={1}
                    textAlign="center"
                  >
                    {EMPTY_DATASET_MESSAGE}
                  </Typography>
                )}
                {activeMedications.map((medication: ActiveMedication) => (
                  <MedicationItem medication={medication} key={medication.name} />
                ))}
              </>
            ) : (
              <VrsErrorMsg size="small" onClick={onActiveMedicationFail} />
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
