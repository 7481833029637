import React, { useEffect, useRef, useState } from "react";
import { EditorState, SelectionState } from "draft-js";
import DraftJsEditor from "@draft-js-plugins/editor";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Popover,
  Snackbar,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import {
  VrsIcon,
  IconName,
  VrsIconButton,
  VrsButton,
  VrsTooltip,
  VrsAlert,
  VrsAvatar,
  verisColors,
} from "@veris-health/web-core";
import { useProfile } from "../../../context/profile";
import { Toolbar } from "../../../ui/components/Editor/Toolbar";
import { Editor } from "../../../ui/components/Editor";
import { TitleInput } from "../containers/PatientCareTeamNotesContainer";
import { useCurrentPatient } from "../../../hooks/useCurrentPatient";
import { generateClinicalNote } from "../api/patientDetailsApi";
import { extractErrorMessage } from "../../shared/helpers";
import { addEmptyBlock, appendText } from "../../../utils/editor";
import { textRegxpPattern } from "../../../constants";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },

  "& .MuiDialog-paper": {
    padding: theme.spacing(4),
    height: "585px",
    width: "600px",
    borderRadius: +theme.shape.borderRadius * 0.5,
    margin: 0,
  },
}));

export interface AddNewNote {
  onSave: (editorState: EditorState, title: string) => void;
  showTitle?: boolean;
  showToolbar?: boolean;
  maxLength?: number;
  generateDocumentationAssistance?: boolean;
}

export function AddNewNote({
  onSave,
  showTitle = true,
  showToolbar = true,
  maxLength,
  generateDocumentationAssistance,
}: AddNewNote): JSX.Element {
  const theme = useTheme();
  const profile = useProfile();
  const currentPatient = useCurrentPatient();
  const [showAddNewNotePopup, setShowAddNewNoteModal] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [generatingContentError, setGeneratingContentError] = useState<string>("");
  const [readOnly, setReadOnly] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const editorRef = useRef<DraftJsEditor>(null);

  const onCreateNewNote = () => {
    if (currentPatient) {
      onSave(editorState, title);
      setShowAddNewNoteModal(false);
    }
  };

  useEffect(() => {
    if (!showAddNewNotePopup) {
      setTitle("");
      setTitleError("");
      setEditorState(EditorState.createEmpty());
    }
    return () => {
      setGeneratingContentError("");
    };
  }, [showAddNewNotePopup]);

  const noteDescription = editorState.getCurrentContent().getPlainText("");

  const generateContent = async () => {
    if (currentPatient && editorState) {
      setGeneratingContentError("");
      let generatedContent;
      await generateClinicalNote(currentPatient?.id)
        .then((response) => {
          generatedContent = response?.generated_clinical_note?.note_text;
          if (generatedContent.length === 0) {
            setGeneratingContentError("No symptoms reported since last appointment");
          }
        })
        .catch((err) => {
          setGeneratingContentError(extractErrorMessage(err));
        });
      try {
        const newEditorState = EditorState.moveSelectionToEnd(
          appendText(addEmptyBlock(editorState), generatedContent ?? ""),
        );
        setEditorState(newEditorState);
      } catch (e) {
        setGeneratingContentError((e as string) ?? "There was an error while generating content");
      }
    }
  };
  return (
    <Box mb={4} mt={5} display="flex" justifyContent="center">
      <VrsButton buttonType="primary" onClick={() => setShowAddNewNoteModal(true)}>
        Add new note
        <span
          style={{
            marginLeft: "5px",
            paddingTop: "3px",
          }}
        >
          <VrsIcon name={IconName.Plus} size={18} stroke={verisColors.neutrals.white} />
        </span>
      </VrsButton>
      <Popover
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={() => setShowAddNewNoteModal(false)}
        open={showAddNewNotePopup}
      >
        <StyledDialog open={showAddNewNotePopup} onClose={() => setShowAddNewNoteModal(false)}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                paddingLeft: () => theme.spacing(0.75),
              }}
            >
              {showTitle && (
                <TitleInput
                  autoFocus
                  placeholder="Write your title"
                  value={title}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length < 61) {
                      if (textRegxpPattern.test(e.target.value)) {
                        setTitle(e.target.value);
                        setTitleError("");
                      } else setTitleError("You can not enter special characters in the title");
                    }
                  }}
                  onMouseDown={(e: React.MouseEvent) => e.stopPropagation()}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.code === "Enter" || e.code === "ArrowDown") {
                      e.preventDefault();
                      e.stopPropagation();

                      const initialEditorState = editorRef.current
                        ?.getPluginMethods()
                        .getEditorState();

                      const currentContent = initialEditorState?.getCurrentContent();
                      if (initialEditorState && currentContent) {
                        editorRef.current
                          ?.getPluginMethods()
                          .setEditorState(
                            EditorState.forceSelection(
                              initialEditorState,
                              SelectionState.createEmpty(
                                currentContent.getBlocksAsArray()[0].getKey(),
                              ),
                            ),
                          );
                        editorRef.current?.focus();
                      }
                    }
                  }}
                />
              )}
              {showTitle && (
                <>
                  <Typography
                    variant="body"
                    sx={{
                      fontWeight: "400",
                      color: verisColors.neutrals["grey-3"],
                    }}
                  >
                    {title.length}/60 Characters
                  </Typography>
                  {titleError && (
                    <Typography variant="caption" color={() => theme.veris.colors.errors.normal}>
                      {titleError}
                    </Typography>
                  )}
                </>
              )}
              <Box
                display="grid"
                gridTemplateColumns="repeat(8, 1fr)"
                gap={1}
                sx={{ marginTop: "1rem", marginBottom: "1rem", width: "70%" }}
              >
                <Box gridColumn="span 2" style={{ alignSelf: "center" }}>
                  <Typography variant="subtitle2" color={verisColors.neutrals["grey-mid"]}>
                    Added by
                  </Typography>
                </Box>
                {profile && (
                  <Box gridColumn="span 6" display="flex" style={{ alignItems: "center" }}>
                    <VrsAvatar size={25} name={`${profile?.first_name} ${profile?.last_name}`} />
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "capitalize", marginLeft: "0.5rem" }}
                    >
                      {profile.first_name} {profile.last_name}
                      {profile.profession && ` • ${profile.profession.replaceAll("_", " ")}`}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <VrsIconButton
              onClick={() => {
                setShowAddNewNoteModal(false);
              }}
              iconProps={{ name: IconName.CloseIcon }}
            />
          </Box>
          {generateDocumentationAssistance && (
            <Box display="flex" style={{ justifyContent: "flex-end", marginBottom: "1rem" }} pb={2}>
              <VrsTooltip
                title="Generate content for the patient - documentation assistance."
                bcgcolor={`${() => theme.veris.colors.neutrals.black}`}
              >
                <VrsButton buttonType="primary" onClick={generateContent}>
                  Generate History
                </VrsButton>
              </VrsTooltip>
            </Box>
          )}
          <DialogContent
            sx={{
              border: `1px solid ${verisColors.neutrals["grey-2"]}`,
              borderRadius: () => +theme.shape.borderRadius * 0.5,
              padding: () => `${theme.spacing(0.75)} !important`,
              overflow: "hidden",
            }}
          >
            {showToolbar && (
              <Toolbar
                editorState={editorState}
                setEditorState={setEditorState}
                setReadOnly={setReadOnly}
              />
            )}
            <Editor
              maxLength={maxLength}
              readOnly={readOnly}
              editorRef={editorRef}
              editorState={editorState}
              onChange={(e: EditorState) => setEditorState(e)}
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "center",
              marginTop: " 1.5rem",
            }}
          >
            <VrsButton
              type="submit"
              buttonType="primary"
              disabled={
                noteDescription.trim().length === 0 || (showTitle && title.trim().length === 0)
              }
              onClick={onCreateNewNote}
            >
              Add new note
            </VrsButton>
          </DialogActions>
        </StyledDialog>
      </Popover>
      {generatingContentError && (
        <Snackbar
          open={!!generatingContentError}
          onClose={() => setGeneratingContentError("")}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          autoHideDuration={5000}
        >
          <VrsAlert
            message={generatingContentError}
            onClose={() => setGeneratingContentError("")}
          />
        </Snackbar>
      )}
    </Box>
  );
}
