import { Box, Button, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { VrsIcon, IconName, VrsTooltip } from "@veris-health/web-core";
import React from "react";

export interface SideBarNavigationItemProps {
  title: string;
  iconName: string;
  active: boolean;
  expanded: boolean;
  onClick: () => void;
}

const sidebarNavigationItemsWidth = { EXPANDED: 259, COLLAPSED: 42.5 };

export function SideBarNavigationItem({
  title,
  iconName,
  active = false,
  expanded = true,
  onClick,
}: SideBarNavigationItemProps): JSX.Element {
  const name = active ? `${iconName}Active` : iconName;
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const theme = useTheme();
  return (
    <Button
      size="small"
      sx={{
        textTransform: "capitalize",
        color: () =>
          active ? theme.veris.colors.amethyst.normal : theme.veris.colors.neutrals.black,
        backgroundColor: () => (active ? theme.veris.colors.amethyst.light : "transparent"),
        minWidth: expanded
          ? `${sidebarNavigationItemsWidth.EXPANDED}px`
          : `${sidebarNavigationItemsWidth.COLLAPSED}px`,
        minHeight: `${sidebarNavigationItemsWidth.COLLAPSED}px`,
        justifyContent: expanded ? "flex-start" : undefined,
        padding: () => (expanded ? theme.spacing(1.25) : theme.spacing(1.25, 1)),
        marginBottom: "2px",
        alignItems: "end",
      }}
      onClick={onClick}
    >
      {!expanded ? (
        <VrsTooltip title={title} bcgcolor={`${() => theme.veris.colors.neutrals.black}`}>
          <Box height="25px" sx={{ alignSelf: "center" }}>
            <VrsIcon name={IconName[name as keyof typeof IconName]} />
          </Box>
        </VrsTooltip>
      ) : (
        <>
          <VrsIcon name={IconName[name as keyof typeof IconName]} sx={{ alignSelf: "center" }} />
          <Typography
            variant="h6"
            sx={{
              padding: () =>
                isSmallScreen ? theme.spacing(0.25, 1, 0.7, 1) : theme.spacing(0.25, 1, 0, 1),
              fontWeight: active && isMediumScreen ? 600 : "inherit",
            }}
          >
            {title}
          </Typography>
        </>
      )}
    </Button>
  );
}
