import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { VrsTooltip, verisColors } from "@veris-health/web-core";
import { getLast3days } from "../../../../utils/date";
import { getSymptomProps, symptomTimelineEventsCalculation } from "../../../../utils/symptoms";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectSymptomFilter } from "../../../../features/shared/slices/patientsSlice";
import { VrsActiveSymptoms } from "../../../../features/shared/interfaces";
import { ExpandedSymptomEvent } from "../../../../features/PatientDetailsMeasurements/measurementSlice";

export const Dot = ({ color }: { color: string }): JSX.Element => {
  const theme = useTheme();
  return (
    <Box
      width={8}
      height={8}
      borderRadius="50%"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      data-test-hook="symptoms-dot"
      sx={{
        backgroundColor: color,
        border: () =>
          color === theme.veris.colors.neutrals.white
            ? `1px solid ${theme.veris.colors.neutrals["grey-2"]}`
            : "none",
      }}
    />
  );
};

export const VrsSymptoms = ({
  symptoms,
  inactive,
}: {
  symptoms: VrsActiveSymptoms;
  inactive?: boolean;
}): JSX.Element => {
  const theme = useTheme();
  const symptomFilter = useAppSelector(selectSymptomFilter);
  const mostRecentSymptom = symptoms?.latestSymptomTimeline?.symptom_name.label;
  const allSymptoms =
    symptoms &&
    symptoms?.allActive?.length &&
    symptoms?.allActive
      .filter((symptom) =>
        symptomFilter && symptomFilter?.length > 0
          ? !symptomFilter?.includes(symptom)
          : symptom !== mostRecentSymptom,
      )
      ?.join(", ");

  const timelineSymptoms =
    symptoms.latestSymptomTimeline &&
    symptomTimelineEventsCalculation(getLast3days(), {
      name: symptoms.latestSymptomTimeline.symptom_name.label,
      events: symptoms?.latestSymptomTimeline.events as ExpandedSymptomEvent[],
    });
  const activeSymptomsLength = symptoms?.allActive?.length;

  return (
    <Box display="flex" flexDirection="column" gap={0.5} maxWidth={150}>
      {activeSymptomsLength ? (
        <>
          <Typography
            variant="body"
            component="div"
            sx={{
              "::first-letter": {
                textTransform: "capitalize",
              },
            }}
            color={() =>
              inactive
                ? theme.veris.colors.neutrals["grey-3"]
                : theme.veris.colors.neutrals["grey-4"]
            }
            data-test-hook="recent-symptom"
          >
            {((symptomFilter && symptomFilter.join(", ")) || mostRecentSymptom) ?? ""}
          </Typography>
          {(!symptomFilter ||
            symptomFilter.length === 0 ||
            symptomFilter.includes(mostRecentSymptom as string)) && (
            <Stack direction="row" spacing={0.5} data-test-hook="symptoms-dots">
              {timelineSymptoms?.events.map((symptom) => (
                <Dot color={getSymptomProps(symptom).fillColor ?? "white"} key={symptom.key} />
              ))}
            </Stack>
          )}
          {activeSymptomsLength > 1 && (
            <VrsTooltip
              title={
                <Typography
                  variant="bodyMedium"
                  sx={{ textTransform: "capitalize" }}
                  data-test-hook="symptoms-addon-tooltip"
                >
                  {allSymptoms}
                </Typography>
              }
              bcgcolor={verisColors.neutrals.black}
            >
              <Typography
                variant="description"
                color={verisColors.neutrals["grey-mid"]}
                sx={{ cursor: "pointer" }}
                data-test-hook="all-symptoms-addon"
              >
                {`+ ${activeSymptomsLength - 1} active`}
              </Typography>
            </VrsTooltip>
          )}
        </>
      ) : (
        <Typography
          variant="caption"
          color={() => theme.veris.colors.neutrals["grey-4"]}
          data-test-hook="no-active-symptoms-message"
        >
          No active symptoms.
        </Typography>
      )}
    </Box>
  );
};
