import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { refreshToken as refreshExpiredToken } from "./refreshToken";
import { checkIfTokenIsExpired } from "./utils/jwt-extras";
import { getToken, setTokensToStorage, terminateTokens } from "./utils/localStorage";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

axiosInstance.defaults.headers.common["Cache-Control"] = "no-store";
axiosInstance.defaults.headers.common.Pragma = "no-cache";

axiosInstance.interceptors.request.use((config) => {
  const authToken = getToken("VERIS_ACCESS_TOKEN");
  const isTokenExpired = authToken ? checkIfTokenIsExpired(authToken) : true;
  if (config.headers && !isTokenExpired) config.headers.Authorization = `Bearer ${authToken}`;

  return config;
});

interface CustomAxiosRequest extends AxiosRequestConfig {
  retry?: boolean;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest: CustomAxiosRequest = error.config as CustomAxiosRequest;
    if (
      error.response?.status === 401 &&
      originalRequest.headers &&
      !originalRequest.retry &&
      !originalRequest.url?.includes("/login-staff") &&
      !originalRequest.url?.includes("/change-credential-staff") &&
      !originalRequest.url?.includes("/refresh")
    ) {
      originalRequest.retry = true;
      const refreshToken = getToken("VERIS_REFRESH_TOKEN");
      if (!refreshToken) return Promise.reject(error);
      try {
        const newTokens = await refreshExpiredToken(refreshToken);
        setTokensToStorage(newTokens);
        originalRequest.headers.Authorization = `Bearer ${newTokens.accessToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        terminateTokens();
        window.location.href = "/";
        return Promise.reject(err);
      }
    } else if (
      error.response?.status === 401 &&
      !error.config?.url?.includes("/login-staff") &&
      !error.config?.url?.includes("/change-credential-staff")
    ) {
      terminateTokens();
      window.location.href = "/";
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);

export default axiosInstance;
