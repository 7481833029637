import React from "react";
import { styled, Typography, Box, useTheme } from "@mui/material";
import { TagItem } from "@veris-health/user-ms/lib/v1";
import { VrsTooltip, verisColors } from "@veris-health/web-core";
import { VrsSingleTag } from "../VrsTags";
import { AddonButton } from "../../../utils/styledComponents";

export interface VrsTagAddonProps {
  additionalTags: TagItem[];
  small?: boolean;
}

const StyledTooltipContent = styled(Box)(() => ({
  backgroundColor: verisColors.amethyst.soft,
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  maxWidth: "12.5rem",
}));

export function VrsTagAddon({ additionalTags, small }: VrsTagAddonProps): JSX.Element {
  const theme = useTheme();
  return (
    <>
      <VrsTooltip
        title={
          <Box
            display="flex"
            flexDirection="column"
            sx={{ padding: () => theme.spacing(0.625, 0.625, 0.938, 0.625) }}
            data-test-hook="tag-addon-tooltip"
          >
            <Typography sx={{ color: verisColors.neutrals["grey-4"] }} variant="bodyMedium">
              Tags:
            </Typography>
            <StyledTooltipContent>
              {additionalTags.map((singleTag) => (
                <VrsSingleTag
                  label={singleTag.label}
                  color={verisColors.amethyst.soft}
                  key={singleTag.label}
                />
              ))}
            </StyledTooltipContent>
          </Box>
        }
        placement="bottom"
        bcgcolor={verisColors.amethyst.soft}
      >
        <AddonButton
          sx={{
            borderRadius: 1.5,
            padding: () => (small ? theme.spacing(0.25, 0.375) : theme.spacing(0.5, 0.75)),
          }}
          data-test-hook="tag-addon-btn"
        >
          <Typography
            variant="caption"
            sx={{ height: "0.875rem" }}
            data-test-hook="tag-addon-content"
          >
            {`+${additionalTags.length}`}
          </Typography>
        </AddonButton>
      </VrsTooltip>
    </>
  );
}
