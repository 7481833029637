import React, { useCallback, useState } from "react";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useMediaQuery, Paper, TableHead, TableRow, useTheme } from "@mui/material";
import { round } from "lodash";
import { TableVirtuoso } from "react-virtuoso";
import { IconName, VrsIcon, VrsButton, VrsTooltip, verisColors } from "@veris-health/web-core";
import { PatientStatusEnumAPI } from "@veris-health/user-ms/lib/v1";
import { StyledTableBody, StyledTableCell, StyledTableRow } from "../shared/StyledTableElements";
import { VrsPatientTags } from "../../VrsPatientTags";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectTogglePreviewBar } from "../../../../features/shared/slices/uiSlice";
import { VrsActiveSymptoms, VrsPatientInfo } from "../../../../features/shared/interfaces";
import { VrsPatientInfoCard } from "../../VrsPatientInfoCard";
import { VrsQualityOfLife } from "../../VrsQualityOfLife";
import { VrsSymptoms } from "../shared/VrsSymptoms";
import { QofLScoreExplanation } from "../../QOLScoreExplanation/QofLScoreExplanation";
import { VrsQofLTrend } from "../../VrsQofLTrend";
import { AdherenceChart } from "../../AdherenceChart";
import { RPMChart } from "../../RPMChart";

export interface InfusionTime {
  // TODO: Enable when BE adds the data
  // nextInfusion: string;
  lastInfusion: string;
}

export interface VrsPatientListTableProps {
  data: VrsPatientInfo[];
  onClickPreview: (patient: VrsPatientInfo) => void;
  onClickHidePreview: () => void;
  onClickFullDetails: ({ id }: { id: number }) => void;
  onPatientClick: (id: number) => void;
  patientToPreview?: VrsPatientInfo;
}

const enum TableColumnNames {
  PatientDetails = "Patient Details",
  HealthStats = "Health Stats",
  QOL = "Quality of Life",
  QoLTrend = "QoL Trend",
  Trend = "Trend",
  Symptoms = "Symptoms",
  Tags = "Tags",
  ActionButtons = "ActionButtons",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  AdherenceChart = "Adherence",
  ClinicalTime = "Clinical Time",
}

interface Column {
  id: TableColumnNames;
  label: TableColumnNames | string;
  width: number;
  tooltipContent?: string | JSX.Element;
  hideOnSmallScreen?: boolean;
}

const columns: readonly Column[] = [
  { id: TableColumnNames.PatientDetails, label: TableColumnNames.PatientDetails, width: 16 },
  {
    id: TableColumnNames.QOL,
    label: TableColumnNames.QOL,
    tooltipContent: <QofLScoreExplanation />,
    width: 6,
  },
  {
    id: TableColumnNames.AdherenceChart,
    label: TableColumnNames.AdherenceChart,
    width: 9.5,
  },
  {
    id: TableColumnNames.ClinicalTime,
    label: TableColumnNames.ClinicalTime,
    width: 9.5,
  },
  { id: TableColumnNames.Symptoms, label: TableColumnNames.Symptoms, width: 9.5 },
  { id: TableColumnNames.Tags, label: TableColumnNames.Tags, width: 11, hideOnSmallScreen: true },
  { id: TableColumnNames.ActionButtons, label: "", width: 15 },
];

export interface PatientRowProps {
  patient: VrsPatientInfo;
  onClickPreview: (patient: VrsPatientInfo) => void;
  onClickHidePreview: () => void;
  onClickFullDetails: ({ id }: { id: number }) => void;
  onPatientClick: (id: number) => void;
  isSmallScreen: boolean;
  getResponsiveWidth: (id: TableColumnNames, width: number) => string | undefined;
  expandedPatientData?: VrsPatientInfo;
}

const PatientRow = ({
  patient,
  onClickPreview,
  onClickHidePreview,
  onClickFullDetails,
  onPatientClick,
  isSmallScreen,
  expandedPatientData,
  getResponsiveWidth,
}: PatientRowProps): JSX.Element => {
  const isPreviewExpanded = useAppSelector(selectTogglePreviewBar);
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [activePatientId, setActivePatientId] = React.useState<number | undefined>(undefined);

  const handleQOlTrendClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, userId: number) => {
      if (event.currentTarget) setAnchorEl(event.currentTarget);
      setActivePatientId(userId);
    },
    [],
  );

  const handleQolTrendClose = useCallback(() => {
    setAnchorEl(null);
    setActivePatientId(undefined);
  }, []);

  return (
    <React.Fragment key={patient.id}>
      {columns.map((column) =>
        column.hideOnSmallScreen && isSmallScreen ? (
          <></>
        ) : (
          <StyledTableCell
            key={column.id}
            sx={{
              borderBottom: () => `1px solid ${theme.veris.colors.neutrals["grey-2"]} !important`,
              minWidth: column.id === TableColumnNames.QOL ? "180px" : "auto",
              width: getResponsiveWidth(column.id, column.width),
              maxWidth: getResponsiveWidth(column.id, column.width),
            }}
          >
            {column.id === TableColumnNames.PatientDetails && (
              <VrsPatientInfoCard
                patientInfo={patient}
                backgroundColor={verisColors.neutrals.white}
                displayBattery
                onClick={onPatientClick}
                styles={{ diagnosis: { maxWidth: "150px" } }}
              />
            )}
            {column.id === TableColumnNames.QOL && (
              <>
                {patient.isInactiveOrDeceased ? (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      color={() => theme.veris.colors.neutrals["grey-3"]}
                      variant="captionSemibold"
                    >
                      {patient.patientStatus === PatientStatusEnumAPI.Inactive
                        ? "Patient Inactive."
                        : "Patient Deceased"}
                    </Typography>
                    <Typography
                      color={() => theme.veris.colors.neutrals["grey-3"]}
                      variant="caption"
                    >
                      No data available.
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <VrsQualityOfLife scoreItem={patient.qualityOfLife} />
                    {patient.qualityOfLifeTrend && (
                      <Typography
                        color={() => theme.veris.colors.amethyst.normal}
                        sx={{ cursor: "pointer", marginTop: 0.5, display: "flex" }}
                        variant="captionSemibold"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                          handleQOlTrendClick(event, patient.id)
                        }
                      >
                        Trend
                        <VrsIcon
                          name={IconName.ArrowRight}
                          size={14}
                          stroke={verisColors.amethyst.normal}
                        />
                      </Typography>
                    )}
                    {patient.qualityOfLifeTrend && anchorEl && activePatientId === patient.id && (
                      <VrsQofLTrend
                        data={
                          patient.qualityOfLifeTrend
                            ? patient?.qualityOfLifeTrend.map((el) => {
                                return {
                                  ...el,
                                  dt: dayjs(el.timestamp).unix(),
                                  eq5d_index: round(el.eq5d_index * 100, 1),
                                };
                              })
                            : []
                        }
                        open={!!anchorEl}
                        toggleDialog={handleQolTrendClose}
                        anchorEl={anchorEl}
                      />
                    )}
                  </Box>
                )}
              </>
            )}
            {column.id === TableColumnNames.AdherenceChart &&
              (patient.isInactiveOrDeceased ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={() => theme.veris.colors.neutrals["grey-3"]}
                    variant="captionSemibold"
                  >
                    {patient.patientStatus === PatientStatusEnumAPI.Inactive
                      ? "Patient Inactive."
                      : "Patient Deceased"}
                  </Typography>
                  <Typography color={() => theme.veris.colors.neutrals["grey-3"]} variant="caption">
                    No data available.
                  </Typography>
                </Box>
              ) : (
                <AdherenceChart ingestionData={patient.ingestionData} />
              ))}

            {column.id === TableColumnNames.Symptoms &&
              (patient.isInactiveOrDeceased ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={() => theme.veris.colors.neutrals["grey-3"]}
                    variant="captionSemibold"
                  >
                    {patient.patientStatus === PatientStatusEnumAPI.Inactive
                      ? "Patient Inactive."
                      : "Patient Deceased"}
                  </Typography>
                  <Typography color={() => theme.veris.colors.neutrals["grey-3"]} variant="caption">
                    No data available.
                  </Typography>
                </Box>
              ) : (
                <VrsSymptoms symptoms={patient.activeSymptoms as VrsActiveSymptoms} />
              ))}

            {column.id === TableColumnNames.ClinicalTime &&
              (patient.isInactiveOrDeceased ? (
                <Box display="flex" flexDirection="column">
                  <Typography
                    color={() => theme.veris.colors.neutrals["grey-3"]}
                    variant="captionSemibold"
                  >
                    {patient.patientStatus === PatientStatusEnumAPI.Inactive
                      ? "Patient Inactive."
                      : "Patient Deceased"}
                  </Typography>
                  <Typography color={() => theme.veris.colors.neutrals["grey-3"]} variant="caption">
                    No data available.
                  </Typography>
                </Box>
              ) : (
                <RPMChart timePassed={patient.rpmSeconds || 0} />
              ))}
            {column.id === TableColumnNames.Tags && patient.tags && !isSmallScreen && (
              <VrsPatientTags
                patientTags={patient.tags}
                patientHospitalId={patient.hospital?.id}
                patientId={patient.id}
                patientName={patient.name}
                inactive={patient.isInactiveOrDeceased}
              />
            )}
            {column.id === TableColumnNames.ActionButtons && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent={isSmallScreen ? "flex-end" : "space-between"}
              >
                <VrsButton
                  onClick={() =>
                    onClickFullDetails({
                      id: patient.id,
                    })
                  }
                  sx={{ marginX: 1 }}
                  buttonType="primary"
                >
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Full Details
                  </Typography>
                </VrsButton>
                {isPreviewExpanded && expandedPatientData?.id === patient.id ? (
                  <VrsButton
                    onClick={onClickHidePreview}
                    sx={{ marginX: 1, minWidth: isSmallScreen ? "96px" : "102px" }}
                    buttonType="secondary"
                    startIcon={
                      <VrsIcon
                        name={IconName.ArrowLeft}
                        stroke={verisColors.amethyst.normal}
                        size={24}
                      />
                    }
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      Hide
                    </Typography>
                  </VrsButton>
                ) : (
                  <VrsButton
                    onClick={() => onClickPreview(patient)}
                    sx={{ marginX: 1 }}
                    buttonType="secondary"
                    endIcon={
                      <VrsIcon
                        name={IconName.ArrowRight}
                        stroke={
                          patient.isInactiveOrDeceased
                            ? verisColors.neutrals["grey-3"]
                            : verisColors.amethyst.normal
                        }
                        size={24}
                      />
                    }
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      Preview
                    </Typography>
                  </VrsButton>
                )}
              </Box>
            )}
          </StyledTableCell>
        ),
      )}
    </React.Fragment>
  );
};

export function VrsPatientListTable({
  data,
  onClickPreview,
  onClickHidePreview,
  onClickFullDetails,
  onPatientClick,
  patientToPreview,
}: VrsPatientListTableProps): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down("xl"));
  const rows = [...data];
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [bottomReached, setBottomReached] = useState<boolean>(false);
  const getResponsiveWidth = (id: TableColumnNames, columnWidth: number): string | undefined => {
    if (isSmallScreen && [TableColumnNames.ActionButtons, TableColumnNames.Tags].includes(id))
      return undefined;
    return isSmallScreen ? `${columnWidth + 2}vw` : `${columnWidth}vw`;
  };
  return (
    <Box
      id="vrs-patient-details-table"
      sx={{
        overflow: "scroll",
        position: "relative",
      }}
    >
      <Paper
        sx={{
          boxShadow: "none",
          "&::after":
            isScrolling && !bottomReached
              ? {
                  content: '"Loading..."',
                  width: "100%",
                  height: "40px",
                  position: "absolute",
                  bottom: 0,
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "white",
                  color: "gray",
                }
              : { content: '""' },
        }}
      >
        <TableVirtuoso
          style={{
            height: "70vh",
            border: `1px solid ${verisColors.neutrals["grey-2"]}`,
            borderRadius: "6px",
          }}
          atBottomStateChange={(atBottom) => setBottomReached(atBottom)}
          data={data}
          isScrolling={setIsScrolling}
          components={{
            Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
            TableHead: React.forwardRef((props, ref) => (
              <TableHead
                {...props}
                ref={ref}
                sx={{
                  backgroundColor: () => theme.veris.colors.neutrals.white,
                  borderBottom: () => `1px solid ${theme.veris.colors.neutrals["grey-2"]}`,
                  top: "-1px",
                }}
              />
            )),
            TableRow,
            TableBody: React.forwardRef((props, ref) => <StyledTableBody {...props} ref={ref} />),
          }}
          fixedHeaderContent={() => (
            <StyledTableRow>
              {columns.map((column) => (
                <React.Fragment key={column.id}>
                  {column.hideOnSmallScreen && isSmallScreen ? (
                    <></>
                  ) : (
                    <StyledTableCell
                      sx={{
                        borderBottom: () =>
                          `1px solid ${theme.veris.colors.neutrals["grey-2"]} !important`,
                        // Adding width to prevent blikining caused by the virutal table inability to predict the max width
                        // of the incoming content,therefore the header blinks while scrolling
                        width: getResponsiveWidth(column.id, column.width),
                        maxWidth: getResponsiveWidth(column.id, column.width),
                      }}
                    >
                      {column.tooltipContent ? (
                        <VrsTooltip
                          bcgcolor={verisColors.neutrals.black}
                          title={column.tooltipContent}
                          placement="bottom"
                        >
                          <Typography
                            variant="subtitle24"
                            component="span"
                            pt={0.5}
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: 500,
                              color: () => theme.veris.colors.neutrals["grey-3"],
                            }}
                          >
                            {column.label}
                          </Typography>
                        </VrsTooltip>
                      ) : (
                        <Typography
                          variant="subtitle24"
                          component="span"
                          pt={0.5}
                          sx={{
                            textTransform: "uppercase",
                            fontWeight: 500,
                            color: () => theme.veris.colors.neutrals["grey-3"],
                          }}
                        >
                          {!(column.id === TableColumnNames.Tags && isSmallScreen)
                            ? column.label
                            : ""}
                        </Typography>
                      )}
                    </StyledTableCell>
                  )}
                </React.Fragment>
              ))}
            </StyledTableRow>
          )}
          itemContent={(index, user) => (
            <PatientRow
              patient={user}
              onClickPreview={(patient) => {
                onClickPreview(patient);
              }}
              onClickHidePreview={() => {
                onClickHidePreview();
              }}
              onClickFullDetails={onClickFullDetails}
              onPatientClick={onPatientClick}
              isSmallScreen={isSmallScreen}
              key={user.id}
              expandedPatientData={patientToPreview}
              getResponsiveWidth={getResponsiveWidth}
            />
          )}
        />
      </Paper>
      {rows.length === 0 && (
        <Typography
          color={() => theme.veris.colors.neutrals["grey-3"]}
          textAlign="center"
          variant="body"
          component="div"
          p={2}
          sx={{
            borderColor: () => theme.veris.colors.neutrals["grey-light"],
            borderStyle: "solid",
            borderWidth: "0px 1px 1px 1px",
          }}
        >
          There are no patients at the moment.
        </Typography>
      )}
    </Box>
  );
}
