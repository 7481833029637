import { Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

export interface VrsSingleTagProps {
  color: string;
  small?: boolean;
  onClick?: () => void;
  selected?: boolean;
  label: string;
}

export function VrsSingleTag({ label, color, small, onClick }: VrsSingleTagProps): JSX.Element {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(() => theme.breakpoints.down("xl"));
  return (
    <Typography
      sx={{
        borderRadius: () => +theme.shape.borderRadius * 0.375,
        color: `${color}`,
        border: `1px solid ${color}`,
        background: () => `${theme.veris.colors.neutrals.white}`,
        padding: () =>
          small ? theme.spacing(0.375, 0.375, 0.25, 0.375) : theme.spacing(0.75, 0.75, 0.5, 0.75),
        margin: () =>
          small ? theme.spacing(0.1875, 0.375, 0.1875, 0) : theme.spacing(0.375, 0.75, 0.375, 0),
        display: "inline-flex",
        maxWidth: isMediumScreen ? "min-content" : "unset",
        cursor: onClick ? "pointer" : "text",
        "span::first-letter": {
          textTransform: "capitalize",
        },
      }}
      component="div"
      variant="bodyMedium"
      data-test-hook="single-tag"
      onClick={onClick}
    >
      <span>{label}</span>
    </Typography>
  );
}
